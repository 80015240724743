<template>
  <div>
    <b-modal
      id="modal-warehouse-show"
      ref="modal"
      v-model="open"
      size="ov-custom"
      no-close-on-backdrop
      hide-footer
      title="Assigned Warehouses"
    >
      <b-overlay
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          :fields="fields"
          :items="rows"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          show-empty
          hover
          responsive
          striped
        >
          <template #empty>
            <TableDataFetching
              :rows="rows"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(ref_no)="data">
            <div
              class="d-flex align-items-center"
            >
              <b-avatar
                :src="`${bucketUrl}${data.item.logo_url}`"
                size="32"
                variant="light-primary"
              />
              <div>
                <div
                  class="font-weight-bolder ml-1"
                >
                  {{ data.value }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(is_agent)="data">
            <b-badge :variant="data.value?'light-success':'light-primary'">
              {{ data.value ? 'Agent' : 'Branch' }}
            </b-badge>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
              cols="12"
              sm="6"
            >
              <span class="text-muted">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              v-if="warehouses.length === 0"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
              cols="12"
              sm="6"
            >

              <b-pagination
                v-model="page"
                :per-page="perPage"
                :total-rows="(meta.total)? meta.total : 0"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCol,
  BRow,
  BOverlay,
  VBModal,
  BPagination,
  BTable,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')

function initialState() {
  return {
    bucketUrl,
    open: false,
    dataLoading: true,
    meta: {},
    perPage: 15,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    rows: [],
    warehouses: [],
    fields: [
      {
        key: 'ref_no',
        label: 'Ref Number',
        sortable: true,
        tdClass: 'text-left',
      },
      {
        key: 'name',
        label: 'Name',
        sortable: true,
      },
      {
        key: 'primary_address',
        label: 'Primary Address',
        sortable: false,
      },
      {
        key: 'is_agent',
        label: 'Warehouse Type',
        sortable: false,
      },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BCol,
    BRow,
    BOverlay,
    BPagination,
    BTable,
    BAvatar,
    BBadge,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  watch: {
    page() {
      this.fetchAssignedWarehouses()
    },
  },
  methods: {
    async openModal(warehouses) {
      await Object.assign(this.$data, initialState())
      this.open = true
      if (warehouses) {
        this.warehouses = warehouses
        this.rows = warehouses
        this.perPage = warehouses.length
        this.meta = {
          from: 1,
          to: warehouses.length,
          total: warehouses.length,
        }
      } else {
        await this.fetchAssignedWarehouses()
      }
    },
    async fetchAssignedWarehouses() {
      try {
        const { data } = (await ResourceRepository.getAssignedWarehouses(this.perPage, this.page))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    handleChangePage(page) {
      this.page = page
      this.fetchAssignedWarehouses()
    },
  },
}
</script>
<style scoped>
</style>
