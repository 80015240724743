<template>
  <!--      Modal Actual -->
  <b-modal
    id="modal-assign-merchant"
    ref="modal"
    v-model="open"
    size="xl"
    no-close-on-backdrop
    hide-footer
    title="ASSIGN RIDER"
  >
    <b-card title="Rate Card - Loyal Riders">
      <b-overlay
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center flex-wrap">
            <b-form-group
              label="Riders"
              class="w-75 mr-1"
            >
              <v-select
                v-model="rider_ids"
                :options="Riders"
                multiple
                deselect-from-dropdown
                :reduce="option => option.id"
                label="first_name"
                @input="handleRemovedValue"
              >
                <template v-slot:option="option">
                  {{ option.first_name }} {{ option.last_name }}
                </template>
              </v-select>
            </b-form-group>
            <b-form-group
              class="mt-md-2"
            >
              <b-button
                v-if="rider_ids.length === 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary="'Select All'"
                variant="outline-primary"
                class="mr-1"
                style="margin-top:4px;"
                @click="onClickSelectAll()"
              >
                <feather-icon icon="CheckSquareIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                style="margin-top:4px;"
                :disabled="rider_ids.length === 0"
                @click="AssignRiders()"
              >
                Assign
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-table
            striped
            hover
            responsive
            show-empty
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  :text="avatarText(data.item.first_name)"
                  size="32"
                  variant="light-primary"
                />
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ data.item.first_name }}
                  </div>
                  <div class="font-small-2 ml-1">
                    {{ data.item.last_name }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(warehouses)="data">
              <div
                class="text-primary"
                @click="onClickShowWarehouses(data.value)"
              >
                {{ data.value.length }} Assigned Warehouse{{ data.value.length > 1 || data.value.length == 0 ? 's' : '' }}
              </div>
            </template>
            <template #cell(is_active)="data">
              <b-badge :variant="data.value? 'light-success':'light-danger'">
                {{ data.value ? 'Active' : 'Inactive' }}
              </b-badge>
            </template>
          </b-table>
        </b-row>
      </b-overlay>
    </b-card>
    <warehouse-modal ref="warehouse_modal" />
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BTable,
  BCol,
  BFormGroup,
  BCard,
  BBadge,
  BRow,
  BOverlay,
  BAvatar,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import WarehouseModal from '@/components/Warehouse/WarehouseModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const RateCardRepository = RepositoryFactory.get('ratecard')
const RiderRepository = RepositoryFactory.get('rider')

function initialState() {
  return {
    avatarText,
    open: false,
    id: null,
    modalLoading: false,
    dataLoading: true,
    meta: {},
    form: {},
    rider_ids: [],
    Riders: [],
    perPage: 20,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
        thClass: 'd-none',
        tdClass: 'd-none',
      }, {
        key: 'first_name',
        label: 'First Name',
        sortable: true,
        tdClass: 'text-left',
      },
      {
        key: 'last_name',
        label: 'Last Name',
        sortable: true,
        tdClass: 'text-left',
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true,
      },
      {
        key: 'warehouses',
        label: 'Warehouses',
        sortable: false,
      },
      { key: 'is_active', label: 'Status', sortable: true },
    ],
    rows: [],
    riderValue: [],
    riderCount: 0,
    isRemoveConfirmed: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BAvatar,
    BCol,
    BFormGroup,
    BCard,
    BRow,
    BTable,
    BOverlay,
    BBadge,
    'v-select': vSelect,
    WarehouseModal,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      await this.fetchAssignedRiderList()
      await this.fetchRiders()
    },
    async fetchRiders() {
      this.modalLoading = true
      try {
        this.Riders = localStorage.getItem('rider_list') ? JSON.parse(localStorage.getItem('rider_list')) : (await RiderRepository.getAllRiderDropDown()).data.data
        if (!localStorage.getItem('rider_list')) localStorage.setItem('rider_list', JSON.stringify(this.Riders))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async fetchAssignedRiderList() {
      this.modalLoading = true
      try {
        const { data } = (await RateCardRepository.getRiderListRateCard(this.id))
        this.rows = data.data
        this.rider_ids = data.data.map(item => item.id)
        this.riderValue = data.data.map(item => item.id)
        this.riderCount = this.rider_ids.length
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
      this.dataLoading = false
    },
    async AssignRiders() {
      this.modalLoading = true
      try {
        const payload = {
          rider_ids: this.rider_ids,
        }
        await RateCardRepository.assignRiderList(this.id, payload)
        this.showSuccessMessage('Riders updated successfully')
        if (!this.isRemoveConfirmed) {
          this.open = false
          this.Riders = []
        }
        this.isRemoveConfirmed = null
        this.$parent.onClickRefresh()
        localStorage.removeItem('rider_list')
        this.fetchAssignedRiderList()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    onClickShowWarehouses(warehouses) {
      this.$refs.warehouse_modal.openModal(warehouses)
    },
    onClickSelectAll() {
      this.rider_ids = this.Riders.map(m => m.id)
    },
    onClickDeselectAll() {
      this.rider_ids = []
    },
    async handleRemovedValue(riderSet) {
      if (riderSet.length > this.riderValue.length) {
        this.riderValue = riderSet.map(item => item)
      } else {
        this.modalLoading = true
        if (this.riderCount !== 0) {
          await this.showMsgBox()
        }
        if (this.isRemoveConfirmed === false) {
          this.rider_ids = this.riderValue.map(item => item)
        }
        if (this.isRemoveConfirmed === true) {
          this.riderValue = riderSet.map(item => item)
          this.AssignRiders()
        }
        this.modalLoading = false
      }
    },
    async showMsgBox() {
      const dialogText = 'Are you certain about removing the rider?'
      return this.$swal({
        title: 'Are you sure?',
        text: dialogText,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, confirm!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: 'modal-no-backdrop',
      }).then(value => {
        this.isRemoveConfirmed = value.isConfirmed
      })
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
